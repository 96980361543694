/* eslint-disable @typescript-eslint/no-explicit-any */

import Workflow from './base';

import { DynamicData } from '@domui-domain/type';

class Individual extends Workflow {
  constructor() {
    super('individualwf');
    // this.setDirectBaseUrl('https://ri-d-usc-dom-indv-wf-aca.gentlebay-a25ffb7b.centralus.azurecontainerapps.io');
  }

  async getDominoIdUsingDisId(disId: string) {
    this.setAction('getDominoId');
    this.setData({
      idType: 'DISID',
      value: disId,
    });
    try {
      const res: any = await this.getCall();
      return res?.data?.wfRes?.dominoId || res?.data?.dominoId || undefined;
    } catch (error) {
      throw new Error('Failed to fetch domino id');
    }
  }

  async getDominoId(nfKey: string) {
    this.setAction('getDominoId');
    this.setData({
      idType: 'NFKey',
      value: nfKey,
    });
    try {
      const res: any = await this.getCall();
      return res?.data?.wfRes?.dominoId || res?.data?.dominoId || undefined;
    } catch (error) {
      throw new Error('Failed to fetch domino id');
    }
  }

  async getDominoIndividual(dominoId: string) {
    this.setAction('getIndividual');
    this.setData({
      id: dominoId,
    });
    try {
      const res = await this.getCall();

      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch individual info');
    }
  }

  async getContactInfo(dominoId: string) {
    this.setAction('getContactInfo');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch contact info');
    }
  }

  async updateContactInfo(dominoId: string, data: DynamicData) {
    this.setAction('updateContactInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as any;
      throw typedError;
    }
  }

  async getProfileInfo(dominoId: string) {
    this.setAction('getProfileInfo');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get info');
    }
  }

  async updateProfileInfo(dominoId: string, data: DynamicData) {
    this.setAction('updateProfileInfo');
    this.setData({
      dominoId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as any;
      throw typedError;
    }
  }

  async getProfessionalExperience(dominoId: string) {
    this.setAction('getProfessionalExperience');
    this.setData({
      dominoId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get professional info');
    }
  }

  async updateProfessionalExperience(
    dominoId: string,
    sharingPermissionId: string,
    data: Record<string, any>
  ) {
    this.setAction('updateProfessionalExperience');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as any;
      throw typedError;
    }
  }

  async updateEditBackground(data: DynamicData) {
    this.setAction('updateEditBackground');
    this.setData({
      putData: data,
    });
    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as any;
      throw typedError;
    }
  }

  async getAreasofExpertise(dominoId: string) {
    this.setAction('getAreasOfExpertise');
    this.setData({
      dominoId,
    });

    const res = await this.getCall();
    return res?.data?.wfRes || res?.data || undefined;
  }

  async updateAreasOfExpertise(
    dominoId: string,
    sharingPermissionId: string,
    data: Record<string, any>
  ) {
    this.setAction('updateAreasOfExpertise');
    this.setData({
      dominoId,
      sharingPermissionId,
      putData: data,
    });

    try {
      const res = await this.putCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      const typedError = error as any;
      throw typedError;
    }
  }
}

export default Individual;
