import { useEffect, useState } from 'react';

// import { uniqBy } from 'lodash/fp';

import { User } from '@domain/auth';
import { SITECORE_USER_TYPES } from '@domain/clubs';

// import { getUserType } from '@use-cases/sitecore/helpers/getUserTypes';

// import { getBackendLogoutUrl, getWebappLogoutUrl } from '@repositories/auth';

// import { constructFullName } from '@utils/constructFullName';
import {
  FEATURE_SITECORE_HEADER,
  FEATURE_SITECORE_HOMEPAGE,
  FEATURE_SITECORE_LANDING_PAGE,
  isEnabled,
} from '@utils/features';
// import { getClubLeadership, getUserClubs } from '@utils/getUserClubs';

import { useDominoUserAccount } from '@domui-hooks/useDominoUserAccount';
import {
  DOMUI_CLUB_DISTRICT_DATA,
  SITECORE_INDIVIDUAL_TOKEN,
} from '@domui-sitecore/constants';
import { getAllDayLabel } from '@domui-sitecore/sitecoreTranslations';

import { useTranslation } from '@external/react-i18next';

// import {
//   ClubAffiliation,
//   ClubLeadership,
//   FetchUserAccountQuery,
// } from '@typings/operations';

export const useSiteCoreUserType = (
  user: User | undefined,
  isProcessingLogin: boolean
) => {
  const { t } = useTranslation();
  const individualId = user?.individualId || '';
  const { data, loading } = useDominoUserAccount(individualId);
  const [areClubsAvailable, setClubsAvailable] = useState(false);

  useEffect(() => {
    if (
      individualId &&
      (isEnabled(FEATURE_SITECORE_HEADER) ||
        isEnabled(FEATURE_SITECORE_HOMEPAGE) ||
        isEnabled(FEATURE_SITECORE_LANDING_PAGE))
    ) {
      setClubsAvailable(true);
    }
  }, [individualId]);

  if (
    isEnabled(FEATURE_SITECORE_HEADER) ||
    isEnabled(FEATURE_SITECORE_HOMEPAGE) ||
    isEnabled(FEATURE_SITECORE_LANDING_PAGE)
  ) {
    if (!isProcessingLogin && !user?.isLoggedIn) {
      window.localStorage.setItem(SITECORE_INDIVIDUAL_TOKEN, '');
      window.localStorage.setItem(DOMUI_CLUB_DISTRICT_DATA, '');

      return SITECORE_USER_TYPES.UNAUTHENTICATED;
    }

    if (loading) {
      window.localStorage.setItem(SITECORE_INDIVIDUAL_TOKEN, '');
      return SITECORE_USER_TYPES.USER_LOADING;
    }

    // const accountInfo = {
    //   ...user?.attributes,
    //   ...data?.individualInfo,
    // };
    // const DESInfo: any = {};

    // const clubsAffiliation =
    //   ((accountInfo as FetchUserAccountQuery['getIndividual'])
    //     ?.clubAffiliations as ClubAffiliation[]) || [];

    // const userClubsForClubsMenuItems = getUserClubs(clubsAffiliation);

    // const specRepDistricts: any = [];

    // const userClubs: ClubLeadership[] = getClubLeadership(
    //   (userClubsForClubsMenuItems as ClubLeadership[]) || [],
    //   t
    // );
    // const desClubs: ClubLeadership[] = getClubLeadership(
    //   (DESInfo?.clubs as ClubLeadership[]) || [],
    //   t
    // );

    // const clubs = uniqBy('clubId', [...userClubs, ...desClubs]);
    // const districts = uniqBy('id', [...specRepDistricts]);

    // const userTypeTranslation = (userRole: string) => {
    //   switch (userRole) {
    //     case SITECORE_USER_TYPES.NON_MEMBER:
    //       return t('sitecore-non-member-greeting', 'Participant');

    //     case SITECORE_USER_TYPES.MEMBER:
    //       return t('sitecore-member-greeting', 'Member');

    //     case SITECORE_USER_TYPES.LEADER:
    //       return t('sitecore-leader-greeting', 'Leader');

    //     default:
    //       return '';
    //   }
    // };

    // const IsDESInfoAvailable = !!DESInfo && clubs && clubs.length > 0;

    const destination = window.location.pathname;
    const newUser =
      !individualId &&
      (destination.endsWith('/account/select-member-status') ||
        destination.endsWith('/account/map-by-email') ||
        destination.endsWith('/contact'));
    const existingUser =
      areClubsAvailable && !isProcessingLogin && user?.isLoggedIn;

    let userType = SITECORE_USER_TYPES.USER_LOADING;
    if (newUser) {
      userType = SITECORE_USER_TYPES.UNAUTHENTICATED;
    } else if (existingUser) {
      // userType = getUserType(clubsAffiliation, IsDESInfoAvailable);
      userType = SITECORE_USER_TYPES.LEADER;
    }
    // const userRole = userTypeTranslation(userType);

    // const { firstName, lastName, middleName } = accountInfo;
    // const fullName = constructFullName(accountInfo);
    // const backendLogoutUrl = getBackendLogoutUrl();
    // const logOutUrl = getWebappLogoutUrl(backendLogoutUrl);
    const weekDayLabels = getAllDayLabel(t);
    const districtLabel = t(
      'search.members.filters-district-label',
      'District'
    );

    // const profileInfo = {
    //   firstName,
    //   lastName,
    //   middleName,
    //   fullName,
    //   userRole,
    // };

    // window.localStorage.setItem(
    //   SITECORE_INDIVIDUAL_TOKEN,
    //   JSON.stringify({
    //     individualId,
    //     clubs,
    //     districts,
    //     profileInfo,
    //     logOutUrl,
    //     weekDayLabels,
    //     districtLabel,
    //   })
    // );

    window.localStorage.setItem(
      DOMUI_CLUB_DISTRICT_DATA,
      JSON.stringify({
        individualInfo: data?.individualInfo,
        getClubDistrictData: data?.getClubDistrictData,
        districtLabel,
        weekDayLabels,
      })
    );

    return userType;
  }
  return SITECORE_USER_TYPES.UNAUTHENTICATED;
};
