import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const useFetchDominoUser = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const fetchDominoUser = async (disId: string) => {
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Individual();
      const memberWfService = new Member();

      const domId = await wfService.getDominoIdUsingDisId(disId);

      const [individualInfo, getClubDistrictData] = await Promise.all([
        wfService.getDominoIndividual(domId),
        memberWfService.GetAffiliationAndClubsInfo(domId),
      ]);

      if (!(individualInfo || getClubDistrictData)) {
        setError({
          message: 'Error while retrieving the data',
        });
      }
      setData({ individualInfo, getClubDistrictData });
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchDominoUser };
};
