import { useEffect, useMemo } from 'react';

import { useFetchDominoUser } from './useFetchDominoUser';

import { useSessionStorage } from '@repositories/storage/hooks';

import { DynamicData } from '@domui-domain/type';

export const useDominoUserAccount = (individualId: string | null) => {
  const { getStorageData, updateStorage } = useSessionStorage<DynamicData>();

  const {
    data: fetchData,
    loading,
    error,
    fetchDominoUser,
  } = useFetchDominoUser();

  const userCacheId = `domino-user-account-${individualId}`;

  useEffect(() => {
    const storageData = getStorageData(userCacheId);

    if (!storageData && individualId) {
      fetchDominoUser(individualId);
    }
  }, [individualId]);

  const data = useMemo(() => {
    const storageData = getStorageData(userCacheId);

    if (!loading && fetchData && individualId) {
      updateStorage(fetchData, userCacheId);
      return fetchData;
    }

    return storageData?.data;
  }, [fetchData, individualId, loading]);
  return { data, loading, error };
};
