import React from 'react';

import { InfoCards as InfoCardsNPM } from '@rotaryintl/component-account-hero';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { createLink } from '@domui-sitecore/utilities/link-helpers';

const HomeAuthenticatedHeroInfoCards = ({ fields: { InfoCards } = {} }) => {
  console.log('Info Card',InfoCards);
  return(
  <InfoCardsNPM
    cards={InfoCards?.map(({ fields: { Title, Description, Link, LinkText, ShowExternalLinkIcon } = {} }) => ({
      title: <Text field={Title} />,
      description: <RichText field={Description} />,
      link: createLink(Link, LinkText, ShowExternalLinkIcon),
    }))}
  />
)};

export default withSitecoreContext()(HomeAuthenticatedHeroInfoCards); 