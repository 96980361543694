/* eslint-disable @typescript-eslint/no-explicit-any */
import Workflow from './base';

class Member extends Workflow {
  constructor() {
    super('memberwf');
  }

  async GetAffiliationAndClubsInfo(individualId: string) {
    this.setAction('GetAffiliationAndClubsInfo');
    this.setData({
      individualId,
    });

    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to get club and district  info');
    }
  }
}

export default Member;
