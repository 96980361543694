
import React, { useEffect, useState } from 'react';

import HomeAuthenticatedHeroNPM from '@rotaryintl/component-account-hero';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { MyRotary_getTokens } from '@domui-sitecore/utilities/helper-functions';
import { createLink, tokenizeLink } from '@domui-sitecore/utilities/link-helpers';
import { replaceTokens } from '@domui-sitecore/utilities/token-helpers';

const HomeAuthenticatedHero = ({
  rendering,
  sitecoreContext,
  fields: { ProfileLink, ProfileLinkText, BackgroundColor, BackgroundImageDesktop, BackgroundImageMobile, Salutation, Role } = {},
}) => {
  const [tokens, setTokens] = useState({});
  useEffect(async () => {
    const tokensResponse = await MyRotary_getTokens(sitecoreContext?.language.toLowerCase());
    setTokens(tokensResponse);
  }, []);
  
  if(ProfileLink?.value?.href){
    ProfileLink.value.href = "/en/domui/profile/{{member-id}}";
  }
  if(ProfileLink?.value?.url){
    ProfileLink.value.url = "/en/domui/profile/{{member-id}}";
  }
  return (
    <HomeAuthenticatedHeroNPM
      backgroundColor={BackgroundColor?.fields?.Value?.value}
      backgroundImageDesktop={BackgroundImageDesktop?.value?.src}
      backgroundImageMobile={BackgroundImageMobile?.value?.src}
      backgroundImageAlt={BackgroundImageDesktop?.value?.alt}
      salutation={replaceTokens(Salutation?.value, tokens)}
      role={replaceTokens(Role?.value, tokens)}
      profileLink={tokenizeLink(createLink(ProfileLink, ProfileLinkText), tokens)}
      cardsPlaceholder={<Placeholder name="authenticated-hero-cards" rendering={rendering} />}
    />
  );
};

export default withSitecoreContext()(HomeAuthenticatedHero); 
